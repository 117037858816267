<template>
    <v-container class="ma-0 pa-0 pb-8">
        <v-row class="pt-10 px-8 pb-12">
            <strong>Filtros</strong>
            <v-spacer></v-spacer>
            <v-btn @click="removeFilter()" small class="elevation-0" style="border:solid 1px #dddddd;">
                <v-icon small class="mr-1">mdi-filter-off</v-icon>Limpiar
            </v-btn>

            <v-text-field label="Folio" v-model="expense.id"></v-text-field>
            <v-text-field label="Folio de Venta" v-model="expense.sale_id"></v-text-field>
            <v-text-field label="Factura de Venta" v-model="expense.invoice"></v-text-field>

            <v-autocomplete v-model="expense.agency" :items="agencyLists" multiple chips :loading="isLoadingAgencies" :search-input.sync="searchAgencies" hide-no-data item-value="id" item-text="name" label="Agencia(s)" placeholder="Escribe para buscar">
                <template slot="no-data"><div class="px-4 py-1">No existen agencias relacionadas.</div></template>  
            </v-autocomplete>

            <v-autocomplete v-model="expense.brand" :items="brandLists" multiple chips :loading="isLoadingBrands" :search-input.sync="searchBrands" hide-no-data item-value="id" item-text="name" label="Marca(s)" placeholder="Escribe para buscar">
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{item.name}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{item.agency.name}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template> 
            </v-autocomplete>

            <v-autocomplete v-model="expense.influencer" :items="influencerLists" multiple chips :loading="isLoadingInfluencers" :search-input.sync="searchInfluencers" hide-no-data item-value="id" item-text="name" label="Influencer" placeholder="Escribe para buscar">
                <template slot="no-data"><div class="px-4 py-1">No existen influencers relacionadas.</div></template>  
            </v-autocomplete>

            <v-autocomplete v-model="expense.user_id" :items="userList" hide-no-data item-value="id" item-text="name" label="Productor(es)" placeholder="Escribe para buscar" attach chips multiple></v-autocomplete>
           
            <v-autocomplete v-model="expense.sale_user_id" :items="userList" hide-no-data item-value="id" item-text="name" label="Responsable" placeholder="Escribe para buscar" attach chips multiple></v-autocomplete>

            <v-select label="Status de Venta" v-model="expense.sale_status" :items="['cancelado', 'vendido']"></v-select>

            <v-text-field label="Servicio de Venta" v-model="expense.service"></v-text-field>

            <v-text-field label="Campaña" v-model="expense.campaign"></v-text-field>

            <v-menu offset-y :close-on-content-click="closeDatePicker(expense.deadline_date)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="expense.deadline_date" label="Fecha Cierre" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expense.deadline_date" range></v-date-picker>
            </v-menu>

            <!--v-menu offset-y :close-on-content-click="closeDatePicker(expense.promise_date)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="expense.promise_date" label="Fecha Promesa" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expense.promise_date" range></v-date-picker>
            </v-menu-->
            <v-menu offset-y :close-on-content-click="closeDatePicker(expense.pay_date)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="expense.pay_date" label="Fecha Pago" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expense.pay_date" range></v-date-picker>
            </v-menu>
            <v-menu offset-y :close-on-content-click="closeDatePicker(expense.invoice_date)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="expense.invoice_date" label="Fecha Factura" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expense.invoice_date" range></v-date-picker>
            </v-menu>
            <v-menu offset-y :close-on-content-click="closeDatePicker(expense.influencer_payment_date)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="expense.influencer_payment_date" label="Fecha Pago Influencer" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expense.influencer_payment_date" range></v-date-picker>
            </v-menu>

            <v-menu offset-y :close-on-content-click="closeDatePicker(expense.created_between)">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field clearable v-model="expense.created_between" label="Fecha de Creación" prepend-icon="mdi-calendar" v-bind="attrs" readonly v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="expense.created_between" range></v-date-picker>
            </v-menu>

            <v-select label="Cancelaciones" v-model="expense.status" :items="[{value:'cancelado', text:'si'}, {value:false, text:'no'}]"></v-select>

            <v-select label="Concepto" v-model="expense.concept" :items="['Producción', 'Viaticos']"></v-select>

            <v-text-field label="Fecha de Servicio Venta" v-model="expense.service_date"></v-text-field>
        </v-row>
        <v-card style="position:absolute; bottom:0; width:100%;" tile color="primary">
            <v-list-item link @click="filter()" dark>
                <v-list-item-content>
                    <strong>Filtrar (0)</strong>
                </v-list-item-content>
            </v-list-item>    
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios"
export default {
    data: () => ({
        areas:[{text:'Mgmt', value:false}, {text:'Booking', value:true}],
        siyno:[{text:'Si', value:true}, {text:'No', value:false}],
        colors:['Verde', 'Amarillo', 'Celeste'],
        expense:{
            id:'',
            sale_id:'',
            influencer_id:[],
            user_id:[],
            created_between:'',
            status:'',
            concept:'',
            sale_user_id:[],
            
            area:'',
            agency:'',
            brand:'',
            service:'',
            service_date:'',
            influencer_payment_date:[],
            invoice:'',
            invoice_date:[],
            promise_date:[],
            deadline_date:[],
            pay_date:[],
            created_between:[],
            campaign:'',
            sale_status:'',
        },
        entries:{agencies:'', brands:'', influencers:''},
        isLoadingAgencies: false,
        isLoadingBrands: false,
        isLoadingInfluencers: false,
        searchAgencies:'',
        searchBrands:'',
        searchInfluencers:''
    }),
    watch: {
        searchInfluencers(val){
            if(val!=null){
                var link = ''
                if(this.currentUser.role == 'Talent Agent' || this.currentUser.role == 'Booking'){
                    link = '&filter[parent_id]=' + this.currentUser.id
                }
                //if (this.influencerLists.length > 0) return
                if (this.isLoadingInfluencers) return
                this.isLoadingInfluencers = true
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/influencer/search?filter[social_networks]='+val+link)
                .then(res => {
                    if(this.entries.influencers.length>0){
                        this.entries.influencers = this.entries.influencers.concat(res.data.data)
                    }else{
                        this.entries.influencers = res.data.data
                    }
                }).finally(() => (this.isLoadingInfluencers = false))
            }   
        },
        searchAgencies(val){
            if (this.isLoadingAgencies) return
            this.isLoadingAgencies = true
            //var filter = this.$store.state.currentUser.client_filter
            axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/agencies?'+'filter[name]='+val)//+filter
            .then(res => {
                //this.provisionalEntriesAgencies = JSON.parse(JSON.stringify(res.data.data))
                if(this.entries.agencies.length>0){
                    this.entries.agencies = this.entries.agencies.concat(res.data.data)
                }else{
                    this.entries.agencies = res.data.data
                }
            }).finally(() => (this.isLoadingAgencies = false))
        },
        searchBrands(val){
            if (this.isLoadingBrand) return
            this.isLoadingBrand = true
            var filter = ''
            if(this.expense.agency_id!=null){
                filter = 'filter[agency_id]=' + this.expense.agency_id + '&'
            }
            axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/brands?' + filter + "filter[name]=" + val)
            .then(res => {
                if(this.entries.brands.length>0){
                    this.entries.brands = this.entries.brands.concat(res.data.data)
                }else{
                    this.entries.brands = res.data.data
                }
            }).finally(() => (this.isLoadingBrand = false))
        },
    },
    computed:{
        currentUser(){
                return this.$store.state.currentUser.user;
            },
        userList(){
            return this.$store.state.user.salesman.map(id => {
                return{
                    id:id.id,
                    name:id.name + ' ' + id.last,
                }
            })
        },
        influencerLists(){
            if(this.entries.influencers!=''){
                var perro = this.entries.influencers.map(id=>{
                    return{
                        id:id.id,
                        name: id.social_networks.instagram,
                        is_booking: id.is_booking
                    }
                })
                return perro
            }
        },
        agencyLists(){
            if(this.entries.agencies!=''){
                return this.entries.agencies.map(id => {
                    return{
                        id:id.id,
                        name:id.name,
                        phone:id.phone,
                        email:id.email
                    }
                })
            }
        },
        brandLists(){
            if(this.entries.brands!=''){
                return this.entries.brands.map(id => {
                    return{
                        id:id.id,
                        name:id.name,
                        agency:id.agency
                    }
                })
            }
        },
    },
    created(){
        if(localStorage.getItem('selectorFiltersExpenses')!=undefined){
            this.expense = JSON.parse(localStorage.getItem('selectorFiltersExpenses'))
            this.entries = JSON.parse(localStorage.getItem('entriesFiltersExpenses'))
        }
    },
    methods:{
        closeDatePicker(dates){
            if(dates!=null && dates.length==2){
                return true
            }else{
                return false
            }
        },
        removeFilter(){
            this.expense={
                id:'',
                sale_id:'',
                influencer_id:'',
                user_id:'',
                created_between:'',
                status:'',
                concept:'',
                sale_user_id:'',
            
                area:'',
                agency:'',
                brand:'',
                service:'',
                service_date:'',
                influencer_payment_date:[],
                invoice:'',
                invoice_date:[],
                promise_date:[],
                deadline_date:[],
                pay_date:[],
                created_between:[],
                campaign:'',
                sale_status:'',
            }
            this.$nextTick(() => {
                this.$emit("filtersExpense", this.expense);
            })
            this.entries.agencies = []
            this.entries.brands = []
            this.entries.influencers = []
            localStorage.removeItem("selectorFiltersExpenses")
            localStorage.removeItem("filtersExpenses")
            localStorage.removeItem("entriesFiltersExpenses")
            localStorage.removeItem("filtersExpensesLength")
            this.$nextTick(() => {
                this.$store.dispatch('expense/getTotals')
                this.$emit("filtersExpense", false);
            })
        },
        filter(){
            var count = 0
            var filter = ''
            //abierto
            if(this.expense.id!=''){
                count = count+1
                filter = filter + '&filter[id]='+this.expense.id
            }
            if(this.expense.sale_id!=''){
                count = count+1
                filter = filter + '&filter[sale_id]='+this.expense.sale_id
            }
            if(this.expense.service!=''){
                count = count+1
                filter = filter + '&filter[sale.service]='+this.expense.service
            }
            if(this.expense.service_date!=''){
                count = count+1
                filter = filter + '&filter[sale.service_date]='+this.expense.service_date
            }
            if(this.expense.invoice!=''){
                count = count+1
                filter = filter + '&filter[sale.invoice]='+this.expense.invoice
            }
            if(this.expense.campaign!=''){
                count = count+1
                filter = filter + '&filter[sale.campaign]='+this.expense.campaign
            }

            //multiples
            if(this.expense.sale_user_id.length>0){
                count = count+1
                filter = filter + '&filter[sale.user_id]='
                var length = this.expense.sale_user_id.length
                for(var i=0; i<length; i++){
                    filter = filter + this.expense.sale_user_id[i]
                    if(length>1 && i<length-1){
                        filter = filter + ','
                    }
                }
            }
            if(this.expense.user_id.length>0){
                count = count+1
                filter = filter + '&filter[user_id]='
                var length = this.expense.user_id.length
                for(var i=0; i<length; i++){
                    filter = filter + this.expense.user_id[i]
                    if(length>1 && i<length-1){
                        filter = filter + ','
                    }
                }
            }
            if(this.expense.agency.length>0){
                count = count+1
                filter = filter + '&filter[sale.agency_id]='
                var length = this.expense.agency.length
                for(var i=0; i<length; i++){
                    filter = filter + this.expense.agency[i]
                    if(length>1 && i<length-1){
                        filter = filter + ','
                    }
                }
            }
            if(this.expense.brand.length>0){
                count = count+1
                filter = filter + '&filter[sale.brand_id]='
                var length = this.expense.brand.length
                for(var i=0; i<length; i++){
                    filter = filter + this.expense.brand[i]
                    if(length>1 && i<length-1){
                        filter = filter + ','
                    }
                }
            }
            
            if(this.expense.influencer_id.length>0){
                count = count+1
                filter = filter + '&filter[influencer_id]='
                var length = this.expense.influencer_id.length
                for(var i=0; i<length; i++){
                    filter = filter + this.expense.influencer_id[i]
                    if(length>1 && i<length-1){
                        filter = filter + ','
                    }
                }
            }
            //select
            if(this.expense.status!==''){
                count = count+1
                if(this.expense.status){
                    filter = filter + '&filter[status]='+this.expense.status
                }
            }
            if(this.expense.concept!==''){
                count = count+1
                filter = filter + '&filter[concept]='+this.expense.concept
            }
            if(this.expense.area!==''){
                count = count+1
                filter = filter + '&filter[sale.influencer.is_booking]='+this.expense.area
            }
            if(this.expense.sale_status!==''){
                count = count+1
                filter = filter + '&filter[sale.sale_status]='+this.expense.sale_status
            }

            //date
            if(this.expense.deadline_date.length==2){
                count = count+1
                filter = filter + '&filter[deadline_date_between]=' + this.expense.deadline_date[0] + ',' + this.expense.deadline_date[1]
            }
            if(this.expense.created_between.length==2){
                count = count+1
                filter = filter + '&filter[created_between]=' + this.expense.created_between[0] + ',' + this.expense.created_between[1]
            }
            if(this.expense.influencer_payment_date.length==2){
                count = count+1
                filter = filter + '&filter[sale.influencer_payment_date]=' + this.expense.influencer_payment_date[0] + ',' + this.expense.influencer_payment_date[1]
            }
            if(this.expense.invoice_date.length==2){
                count = count+1
                filter = filter + '&filter[sale.invoice_date]=' + this.expense.invoice_date[0] + ',' + this.expense.invoice_date[1]
            }
            if(this.expense.promise_date.length==2){
                count = count+1
                filter = filter + '&filter[sale.promise_date]=' + this.expense.promise_date[0] + ',' + this.expense.promise_date[1]
            }
            if(this.expense.pay_date.length==2){
                count = count+1
                filter = filter + '&filter[sale.pay_date]=' + this.expense.pay_date[0] + ',' + this.expense.pay_date[1]
            }
            if(this.expense.created_between.length==2){
                count = count+1
                filter = filter + '&filter[sale.created_between]=' + this.expense.created_between[0] + ',' + this.expense.created_between[1]
            }

            //save
            localStorage.setItem('selectorFiltersExpenses', JSON.stringify(this.expense));
            localStorage.setItem('entriesFiltersExpenses', JSON.stringify(this.entries));
            localStorage.setItem('filtersExpenses', JSON.stringify(filter.slice(1,filter.length)));
            localStorage.setItem('filtersExpensesLength', count)

            this.$nextTick(() => {
                this.$emit("filtersExpense", false);
                this.$store.dispatch('expense/getTotals')
            })
        }
    }
}
</script>

<style>
.v-text-field {
    padding-top: 10px;
    margin-top: 0px;
}
</style>

